// constants
import { weekdays } from 'helpers/constants';
// Types
import { TQueryParams, IMessageRes } from 'types/appTypes';

export interface ICompanyItem {
  id: number;
  companyId: number;
  companyName: string;
  businessCompaniesCount: number;
  usersCount: number;
  lastLoginDate: string;
  totalPaidAmount: number;
  nextPaymentDate: string;
  nextPaymentAmount: number;
  accountName: string;
  emailVerified: boolean;
  signUpDate: Date;
  usedStorage: number;
}

export interface IGetCompaniesRes {
  count: number;
  items: ICompanyItem[];
}

export interface IGetLogInAsCompanyRes {
  userId: number;
  frontAppUrl: string;
  accountName: string;
  tokens: {
    accessToken: string;
    refreshToken: string;
  };
}

export interface IDeleteCompaniesParams {
  id: number;
  params?: TQueryParams;
}

export type IDeleteCompaniesRes = IMessageRes;

export enum ECompanyPaidStatus {
  Trial = 'Trial',
  Free = 'Free',
  Paid = 'Paid',
}

export enum ESubscriptionStatus {
  Active = 'active',
  Canceled = 'canceled',
  Trialing = 'trialing',
  Unpaid = 'unpaid',
  Expired = 'expired',
  NoSubscription = 'no Subscription',
}

export interface IGetCompanyDetailsRes {
  id: number;
  companyData: {
    businessName: string;
    industryName: string;
    accountName: string;
    country: string;
    city: string;
    timezone: string;
    employeesNumber: number;
    payrollStartDay: weekdays;
    payrollStartFrom: Date;
    payrollGenerationFrequency: PayrollGenerationFrequency;
    payrollTrackOvertime: boolean;
    userId: number;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    paidStatus: ECompanyPaidStatus;
    trialEndDate: Date;
    sourceName: string;
    partnerCode: string;
  };
  subscriptionSettings: ISubscriptionSettings;
}

enum PayrollGenerationFrequency {
  Monthly = 'monthly',
  Weekly = 'weekly',
  SemiMonthly = 'semi-monthly',
  Biweekly = 'biweekly',
}

export interface ISubscriptionSettings {
  monthlyBaseRate: number;
  usedStorageRate: number;
  paidStatus: ECompanyPaidStatus;
  activeUserRateId: number;
  partnerId?: number;
  partnerNotes?: string;
  trialEndDate: string;
  status: ESubscriptionStatus;
}

export interface IUpdateCompanyParamWithBody {
  id: number;
  body: Omit<ISubscriptionSettings, 'status'>;
}

export type IUpdateCompanyRes = IMessageRes;

//* ENDPOINT "/admin/subscription-variants METHOD "GET" */

export interface ISubscriptionItem {
  id: number;
  name: string;
  productName: string;
  price: 0;
  currency: string;
}

export interface ISubscriptionItemsRes {
  count: number;
  items: ISubscriptionItem[];
}
export interface IIndustryItem {
  id: number;
  name: string;
}

export type TGetIndustriesRes = IIndustryItem[];
